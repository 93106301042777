const consentCountries={
    "Octapharma | Russian": [
    "Афганистан",
    "Åland Islands", 
    "Албания",
    "Алжир",
    "Американское Самоа",
    "Андорра",
    "Ангола",
    "Ангилья",
    "Антарктида",
    "Антигуа и Барбуда",
    "Аргентина",
    "Армения",
    "Аруба",
    "Австралия",
    "Австрия",
    "Азербайджан",
    "Бахрейн",
    "Бангладеш",
    "Барбадос",
    "Беларусь",
    "Бельгия",
    "Белиз",
    "Бенин",
    "Бермуды",
    "Бутан",
    "Боливия",
    "Бонэйр, Синт-Эстатиус и Саба",
    "Босния и Герцеговина",
    "Ботсвана",
    "Остров Буве",
    "Бразилия",
    "Британская территория в Индийском океане",
    "Бруней",
    "Болгария",
    "Буркина-Фасо",
    "Бурунди",
    "Камбоджа",
    "Камерун",
    "Канада",
    "Кабо-Верде",
    "Каймановы острова",
    "Центральноафриканская Республика",
    "Чад",
    "Чили",
    "Китай",
    "Остров Рождества",
    "Кокосовые (Килинг) острова",
    "Колумбия",
    "Коморы",
    "Конго",
    "Острова Кука",
    "Коста-Рика",
    "Кот-д’Ивуар",
    "Хорватия",
    "Куба",
    "Кюрасао",
    "Кипр",
    "Чешская Республика",
    "Демократическая Республика Конго",
    "Дания",
    "Джибути",
    "Доминика",
    "Доминиканская Республика",
    "Эквадор",
    "Египет",
    "Сальвадор",
    "Экваториальная Гвинея",
    "Эритрея",
    "Эстония",
    "Эсватини",
    "Эфиопия",
    "Фолклендские острова",
    "Фарерские острова",
    "Фиджи",
    "Финляндия",
    "Франция",
    "Французская Гвиана",
    "Французская Полинезия",
    "Французские Южные и Антарктические территории",
    "Габон",
    "Гамбия",
    "Грузия",
    "Германия",
    "Гана",
    "Гибралтар",
    "Греция",
    "Гренландия",
    "Гренада",
    "Гваделупа",
    "Гуам",
    "Гренада",
    "Гренада",
    "Гвинея",
    "Гвинея-Бисау",
    "Гайана",
    "Гаити",
    "Острова Херд и Макдональд",
    "Гондурас",
    "Гонконг (САР)",
    "Венгрия",
    "Исландия",
    "Индия",
    "Индонезия",
    "Иран",
    "Ирак",
    "Ирландия",
    "Израиль",
    "Италия",
    "Ямайка",
    "Япония",
    "Джерси",
    "Иордания",
    "Казахстан",
    "Кения",
    "Кирибати",
    "Косово",
    "Кувейт",
    "Кыргызстан",
    "Лаос",
    "Латвия",
    "Ливан",
    "Лесото",
    "Либерия",
    "Ливия",
    "Лихтенштейн",
    "Литва",
    "Люксембург",
    "Макао (САР)",
    "Мадагаскар",
    "Малави",
    "Малайзия",
    "Мальдивы",
    "Мали",
    "Мальта",
    "Остров Мэн",
    "Маршалловы острова",
    "Мартиника",
    "Мавритания",
    "Маврикий",
    "Майотта",
    "Мексика",
    "Микронезия",
    "Молдова",
    "Монако",
    "Монголия",
    "Черногория",
    "Монтсеррат",
    "Марокко",
    "Мозамбик",
    "Мьянма",
    "Намибия",
    "Науру",
    "Непал",
    "Нидерланды",
    "Новая Каледония",
    "Новая Зеландия",
    "Никарагуа",
    "Нигер",
    "Нигерия",
    "Ниуе",
    "Остров Норфолк",
    "Северная Корея",
    "Северная Македония",
    "Северные Марианские острова",
    "Норвегия",
    "Оман",
    "Пакистан",
    "Палау",
    "Оккупированная Палестинская территория",
    "Панама",
    "Папуа — Новая Гвинея",
    "Парагвай",
    "Перу",
    "Филиппины",
    "Острова Питкерн",
    "Польша",
    "Португалия",
    "Пуэрто-Рико",
    "Катар",
    "Реюньон",
    "Румыния",
    "Россия",
    "Руанда",
    "Остров Святой Елены",
    "Сент-Китс и Невис",
    "Сент-Люсия",
    "Сен-Пьер и Микелон",
    "Сент-Винсент и Гренадины",
    "Сен-Бартельми",
    "Сен-Мартен (французская часть)",
    "Самоа",
    "Сан-Марино",
    "Сан-Томе и Принсипи",
    "Саудовская Аравия",
    "Сенегал",
    "Сербия",
    "Сейшелы",
    "Сьерра-Леоне",
    "Сингапур",
    "Синт-Мартен (голландская часть)",
    "Словакия",
    "Словения",
    "Соломоновы острова",
    "Сомали",
    "Южноафриканская Республика",
    "Южная Георгия и Южные Сандвичевы острова",
    "Южная Корея",
    "Южный Судан",
    "Испания",
    "Шри-Ланка",
    "Судан",
    "Суринам",
    "Шпицберген и Ян Майен",
    "Швеция",
    "Швейцария",
    "Сирия",
    "Тайвань",
    "Таджикистан",
    "Танзания",
    "Таиланд",
    "Багамские острова",
    "Восточный Тимор",
    "Того",
    "Токелау",
    "Тонга",
    "Тринидад и Тобаго",
    "Тунис",
    "Турция",
    "Туркменистан",
    "Острова Тёркс и Кайкос",
    "Тувалу",
    "Уганда",
    "Украина",
    "Объединенные Арабские Эмираты",
    "Великобритания",
    "Соединенные Штаты Америки",
    "Мелкие зависимые территории США",
    "Уругвай",
    "Узбекистан",
    "Вануату",
    "Ватикан",
    "Венесуэла",
    "Вьетнам",
    "Британские Виргинские острова",
    "Американские Виргинские острова",
    "Уоллис и Футуна",
    "Западная Сахара",
    "Йемен",
    "Замбия",
    "Зимбабве"
  ],
  "Octapharma | Spanish":[
    "Afganistán",
    "Islas Åland", // No direct translation, commonly used as is.
    "Albania",
    "Argelia",
    "Samoa Americana",
    "Andorra",
    "Angola",
    "Anguila",
    "Antártida",
    "Antigua y Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaiyán",
    "Bahréin",
    "Bangladesh",
    "Barbados",
    "Bielorrusia",
    "Bélgica",
    "Belice",
    "Benín",
    "Bermudas",
    "Bután",
    "Bolivia",
    "Bonaire, San Eustaquio y Saba",
    "Bosnia y Herzegovina",
    "Botsuana",
    "Isla Bouvet",
    "Brasil",
    "Territorio Británico del Océano Índico",
    "Brunéi",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Camboya",
    "Camerún",
    "Canadá",
    "Cabo Verde",
    "Islas Caimán",
    "República Centroafricana",
    "Chad",
    "Chile",
    "China",
    "Isla de Navidad",
    "Islas Cocos (Keeling)",
    "Colombia",
    "Comoras",
    "Congo",
    "Islas Cook",
    "Costa Rica",
    "Costa de Marfil",
    "Croacia",
    "Cuba",
    "Curazao",
    "Chipre",
    "República Checa",
    "República Democrática del Congo",
    "Dinamarca",
    "Yibuti",
    "Dominica",
    "República Dominicana",
    "Ecuador",
    "Egipto",
    "El Salvador",
    "Guinea Ecuatorial",
    "Eritrea",
    "Estonia",
    "Esuatini",
    "Etiopía",
    "Islas Malvinas",
    "Islas Faroe",
    "Fiyi",
    "Finlandia",
    "Francia",
    "Guayana Francesa",
    "Polinesia Francesa",
    "Territorios Franceses del Sur",
    "Gabón",
    "Gambia",
    "Georgia",
    "Alemania",
    "Ghana",
    "Gibraltar",
    "Grecia",
    "Groenlandia",
    "Granada",
    "Guadalupe",
    "Guam",
    "Guatemala",
    "Guernesey y Alderney",
    "Guinea",
    "Guinea-Bisáu",
    "Guyana",
    "Haití",
    "Islas Heard y McDonald",
    "Honduras",
    "Hong Kong (R.A.E.)",
    "Hungría",
    "Islandia",
    "India",
    "Indonesia",
    "Irán",
    "Irak",
    "Irlanda",
    "Israel",
    "Italia",
    "Jamaica",
    "Japón",
    "Jersey",
    "Jordania",
    "Kazajistán",
    "Kenia",
    "Kiribati",
    "Kosovo",
    "Kuwait",
    "Kirguistán",
    "Laos",
    "Letonia",
    "Líbano",
    "Lesoto",
    "Liberia",
    "Libia",
    "Liechtenstein",
    "Lituania",
    "Luxemburgo",
    "Macao (R.A.E.)",
    "Madagascar",
    "Malawi",
    "Malasia",
    "Maldivas",
    "Malí",
    "Malta",
    "Isla de Man",
    "Islas Marshall",
    "Martinica",
    "Mauritania",
    "Mauricio",
    "Mayotte",
    "México",
    "Micronesia",
    "Moldavia",
    "Mónaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Marruecos",
    "Mozambique",
    "Birmania",
    "Namibia",
    "Nauru",
    "Nepal",
    "Países Bajos",
    "Nueva Caledonia",
    "Nueva Zelanda",
    "Nicaragua",
    "Níger",
    "Nigeria",
    "Niue",
    "Isla Norfolk",
    "Corea del Norte",
    "Macedonia del Norte",
    "Islas Marianas del Norte",
    "Noruega",
    "Omán",
    "Pakistán",
    "Palaú",
    "Territorio Palestino Ocupado",
    "Panamá",
    "Papúa Nueva Guinea",
    "Paraguay",
    "Perú",
    "Filipinas",
    "Islas Pitcairn",
    "Polonia",
    "Portugal",
    "Puerto Rico",
    "Catar",
    "Reunión",
    "Rumania",
    "Rusia",
    "Ruanda",
    "San Helena",
    "San Cristóbal y Nieves",
    "Santa Lucía",
    "San Pedro y Miquelón",
    "San Vicente y las Granadinas",
    "San Bartolomé",
    "San Martín (parte francesa)",
    "Samoa",
    "San Marino",
    "Santo Tomé y Príncipe",
    "Arabia Saudita",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leona",
    "Singapur",
    "Sint Maarten (parte neerlandesa)",
    "Eslovaquia",
    "Eslovenia",
    "Islas Salomón",
    "Somalia",
    "Sudáfrica",
    "Georgia del Sur",
    "Corea del Sur",
    "Sudán del Sur",
    "España",
    "Sri Lanka",
    "Sudán",
    "Surinam",
    "Svalbard y Jan Mayen",
    "Suecia",
    "Suiza",
    "Siria",
    "Taiwán",
    "Tayikistán",
    "Tanzania",
    "Tailandia",
    "Las Bahamas",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad y Tobago",
    "Túnez",
    "Turquía",
    "Turkmenistán",
    "Islas Turcas y Caicos",
    "Tuvalu",
    "Uganda",
    "Ucrania",
    "Emiratos Árabes Unidos",
    "Reino Unido",
    "Estados Unidos",
    "Islas menores alejadas de los Estados Unidos",
    "Uruguay",
    "Uzbekistán",
    "Vanuatu",
    "Ciudad del Vaticano",
    "Venezuela",
    "Vietnam",
    "Islas Vírgenes Británicas",
    "Islas Vírgenes de los EE.UU.",
    "Wallis y Futuna",
    "Sáhara Occidental",
    "Yemen",
    "Zambia",
    "Zimbabue"
  ],
  "Octapharma | German":[
    "Afghanistan",
    "Åland-Inseln", // No direct translation, commonly used as is.
    "Albanien",
    "Algerien",
    "Amerikanisch-Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarktis",
    "Antigua und Barbuda",
    "Argentinien",
    "Armenien",
    "Aruba",
    "Australien",
    "Österreich",
    "Aserbaidschan",
    "Bahrain",
    "Bangladesch",
    "Barbados",
    "Weißrussland",
    "Belgien",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivien",
    "Bonaire, Sint Eustatius und Saba",
    "Bosnien und Herzegowina",
    "Botswana",
    "Bouvetinsel",
    "Brasilien",
    "Britisches Territorium im Indischen Ozean",
    "Brunei",
    "Bulgarien",
    "Burkina Faso",
    "Burundi",
    "Kambodscha",
    "Kamerun",
    "Kanada",
    "Kap Verde",
    "Kaimaninseln",
    "Zentralafrikanische Republik",
    "Tschad",
    "Chile",
    "China",
    "Weihnachtsinsel",
    "Kokosinseln (Keeling)",
    "Kolumbien",
    "Komoren",
    "Kongo",
    "Cookinseln",
    "Costa Rica",
    "Elfenbeinküste",
    "Kroatien",
    "Kuba",
    "Curaçao",
    "Zypern",
    "Tschechische Republik",
    "Demokratische Republik Kongo",
    "Dänemark",
    "Dschibuti",
    "Dominica",
    "Dominikanische Republik",
    "Ecuador",
    "Ägypten",
    "El Salvador",
    "Äquatorialguinea",
    "Eritrea",
    "Estland",
    "Eswatini",
    "Äthiopien",
    "Falklandinseln",
    "Färöer-Inseln",
    "Fidschi",
    "Finnland",
    "Frankreich",
    "Französisch-Guayana",
    "Französisch-Polynesien",
    "Französische Süd- und Antarktisgebiete",
    "Gabon",
    "Gambia",
    "Georgien",
    "Deutschland",
    "Ghana",
    "Gibraltar",
    "Griechenland",
    "Grönland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey und Alderney",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard- und McDonald-Inseln",
    "Honduras",
    "Hongkong (S.A.R.)",
    "Ungarn",
    "Island",
    "Indien",
    "Indonesien",
    "Iran",
    "Irak",
    "Irland",
    "Israel",
    "Italien",
    "Jamaika",
    "Japan",
    "Jersey",
    "Jordanien",
    "Kasachstan",
    "Kenia",
    "Kiribati",
    "Kosovo",
    "Kuwait",
    "Kirgisistan",
    "Laos",
    "Lettland",
    "Libanon",
    "Lesotho",
    "Liberia",
    "Libyen",
    "Liechtenstein",
    "Litauen",
    "Luxemburg",
    "Macao (S.A.R.)",
    "Madagaskar",
    "Malawi",
    "Malaysia",
    "Malediven",
    "Mali",
    "Malta",
    "Insel Man",
    "Marshallinseln",
    "Martinique",
    "Mauretanien",
    "Mauritius",
    "Mayotte",
    "Mexiko",
    "Mikronesien",
    "Moldawien",
    "Monaco",
    "Mongolei",
    "Montenegro",
    "Montserrat",
    "Marokko",
    "Mosambik",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Niederlande",
    "Neukaledonien",
    "Neuseeland",
    "Nikaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolkinsel",
    "Nordkorea",
    "Nordmazedonien",
    "Nördliche Marianen",
    "Norwegen",
    "Oman",
    "Pakistan",
    "Palau",
    "Besetztes Palästinensergebiet",
    "Panama",
    "Papua-Neuguinea",
    "Paraguay",
    "Peru",
    "Philippinen",
    "Pitcairninseln",
    "Polen",
    "Portugal",
    "Puerto Rico",
    "Katar",
    "Réunion",
    "Rumänien",
    "Russland",
    "Ruanda",
    "Saint Helena",
    "St. Kitts und Nevis",
    "St. Lucia",
    "Saint-Pierre und Miquelon",
    "St. Vincent und die Grenadinen",
    "Saint-Barthélemy",
    "Saint-Martin (französischer Teil)",
    "Samoa",
    "San Marino",
    "São Tomé und Príncipe",
    "Saudi-Arabien",
    "Senegal",
    "Serbien",
    "Seychellen",
    "Sierra Leone",
    "Singapur",
    "Sint Maarten (niederländischer Teil)",
    "Slowakei",
    "Slowenien",
    "Salomonen",
    "Somalia",
    "Südafrika",
    "Südgeorgien",
    "Südkorea",
    "Südsudan",
    "Spanien",
    "Sri Lanka",
    "Sudan",
    "Surinam",
    "Svalbard und Jan Mayen",
    "Schweden",
    "Schweiz",
    "Syrien",
    "Taiwan",
    "Tadschikistan",
    "Tansania",
    "Thailand",
    "Bahamas",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad und Tobago",
    "Tunesien",
    "Türkei",
    "Turkmenistan",
    "Turks- und Caicosinseln",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "Vereinigte Arabische Emirate",
    "Vereinigtes Königreich",
    "Vereinigte Staaten",
    "Vereinigte Staaten von Amerika (außerhalb des Festlandes)",
    "Uruguay",
    "Usbekistan",
    "Vanuatu",
    "Vatikanstadt",
    "Venezuela",
    "Vietnam",
    "Britische Jungferninseln",
    "Amerikanische Jungferninseln",
    "Wallis und Futuna",
    "Westsahara",
    "Jemen",
    "Sambia",
    "Simbabwe"
  ],
  "Octapharma | English":[
    "Afghanistan",
    "Aland Islands",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo",
    "Cook Islands",
    "Costa Rica",
    "Cote D'Ivoire (Ivory Coast)",
    "Croatia",
    "Cuba",
    "Curaçao",
    "Cyprus",
    "Czech Republic",
    "Democratic Republic of the Congo",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Falkland Islands",
    "Faroe Islands",
    "Fiji Islands",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gabon",
    "Gambia The",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey and Alderney",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and McDonald Islands",
    "Honduras",
    "Hong Kong S.A.R.",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macau S.A.R.",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Man (Isle of)",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "North Korea",
    "North Macedonia",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestinian Territory Occupied",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn Island",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Reunion",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Helena",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Saint-Barthelemy",
    "Saint-Martin (French part)",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten (Dutch part)",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Svalbard and Jan Mayen Islands",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "The Bahamas",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "United States Minor Outlying Islands",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City State (Holy See)",
    "Venezuela",
    "Vietnam",
    "Virgin Islands (British)",
    "Virgin Islands (US)",
    "Wallis and Futuna Islands",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe"
  ]
}

export default consentCountries;